<template>
  <div class="flex-grow flex flex-col">
    <div
      class="container mx-auto flex-grow py-24 flex justify-center items-center"
    >
      <!-- Success Upload -->
      <div
        class="w-full max-w-600 mx-auto bg-green-100 rounded-t shadow overflow-hidden border-0 border-b-4 border-solid border-green-500"
        v-if="success || previewFiles.length"
      >
        <div class="md:flex">
          <div class="w-full p-3 py-10">
            <div class="flex justify-center">
              <img src="/icons/valid.png" width="80" />
            </div>
            <div class="flex justify-center mt-3">
              <span class="text-2xl font-EffraR text-green-800">
                {{ $t("patient.success") }} !
              </span>
            </div>
            <p class="px-16 text-center text-gray-700 font-EffraR tetx-xl">
              {{ $t("patient.text_document_sent_success") }}
            </p>
          </div>
        </div>
      </div>

      <!-- Other -->
      <div v-if="!success && !previewFiles.length">
        <!-- Header -->
        <div class="text-left">
          <span class="text-3xl text-black font-EffraR">
            {{ $t("patient.title_active_account") }}
          </span>
        </div>
        <div class="text-xl font-EffraR text-black my-6">
          {{ $t("patient.text_document_sent") }}
        </div>
        <!-- Upload Actions -->
        <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
          <div class="relative">
            <div
              class="min-h-200 rounded-cu cursor-pointer relative p-5"
              style="background : #f6f6f6"
            >
              <input
                @change="setFiles('CIN')"
                type="file"
                class="absolute top-0 h-full w-full opacity-0 cursor-pointer"
              />
              <div class="text-2xl text-black font-EffraM">
                {{ $t("patient.upload_cin_title") }}
              </div>
              <p class="text-lg font-EffraR mb-6" style="color: #767676;">
                {{ $t("patient.access_ext_files") }}
              </p>

              <div
                class="bg-white rounded-cu border border-dashed flex flex-row items-center gap-x-4 h-100 px-10"
                style="border-color: #c4c4c4;"
              >
                <img src="/artwork/id_card.png" />
                <span class="text-base font-EffraR" style="color: #767676;">
                  {{ $t("patient.drag_document_here") }}
                </span>
              </div>
            </div>
            <div
              class="bg-dokBlue-ultra rounded-b-cu py-3 text-center"
              v-if="getFiles('CIN')[0]"
            >
              <span class="text-lg font-semibold text-white">
                {{ getFiles("CIN")[0].name }}
              </span>
            </div>
          </div>
          <div class="relative">
            <div
              class="min-h-200 rounded-cu cursor-pointer relative p-5"
              style="background : #f6f6f6"
            >
              <input
                @change="setFiles('PRO-ID')"
                type="file"
                class="absolute top-0 h-full w-full opacity-0 cursor-pointer"
              />
              <div class="text-2xl text-black font-EffraM">
                {{ $t("patient.upload_CPEM") }}
              </div>
              <p class="text-lg font-EffraR mb-6" style="color: #767676;">
                {{ $t("patient.access_ext_files") }}
              </p>

              <div
                class="bg-white rounded-cu border border-dashed flex flex-row items-center gap-x-4 h-100 px-10"
                style="border-color: #c4c4c4;"
              >
                <img src="/artwork/passport.png" />
                <span class="text-base font-EffraR" style="color: #767676;">
                  {{ $t("patient.drag_document_here") }}
                </span>
              </div>
            </div>
            <div
              class="bg-dokBlue-ultra rounded-b-cu py-3 text-center"
              v-if="getFiles('PRO-ID')[0]"
            >
              <span class="text-lg font-semibold text-white">
                {{ getFiles("PRO-ID")[0].name }}
              </span>
            </div>
          </div>
        </div>
        <!-- Other Text -->
        <div
          class="flex flex-row items-center font-EffraR text-base my-5"
          style="color: #767676;"
        >
          <img src="/svg/info.svg" class="mr-3" />
          {{ $t("patient.document_delete_auto") }}
        </div>
        <!-- Progress Upload -->
        <div
          v-for="(file, index) in files"
          :key="file.type"
          class="bg-gray-100 mb-5 py-3 px-2 shadow border border-solid border-gray-400 rounded-cu flex items-center"
        >
          <img
            :src="
              file.file.type === 'image/jpeg'
                ? '/svg/png.svg'
                : '/svg/unknown.svg'
            "
            class="h-12 w-12 mr-3"
          />
          <div class="flex flex-col w-full">
            <div class="flex justify-between w-full">
              <span class="font-semibold"
                >{{
                  file.type === "CIN"
                    ? $t("patient.cart_national")
                    : $t("patient.cart_cpem")
                }}
                : ({{ file.file.name }})
              </span>
              <span class="font-semibold text-dokBlue-ultra text-shadow">
                <template v-if="isStartUpload"> {{ percentage }}% </template>

                <i
                  class="far fa-trash-alt cursor-pointer text-blackdok hover:text-red-700 ml-3"
                  @click="deleteFile(index)"
                ></i>
              </span>
            </div>
            <div class="relative pt-1" v-if="isStartUpload">
              <div
                class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-pink-200"
              >
                <div
                  :style="`width: ${percentage}%;`"
                  class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <!-- Button Save -->
        <div class="flex justify-center">
          <div class="flex flex-col">
            <button
              v-if="true"
              @click="onSent"
              class="bg-dokBlue-ultra text-white border-0 rounded-cu font-EffraR text-lg py-4 px-3 cursor-pointer w-450 mb-8"
            >
              {{ $t("patient.sent_documents") }}
            </button>

            <span
              class="text-center font-EffraR text-lg"
              style="color: #767676;"
            >
              {{ $t("patient.sent_in_whatsapp") }} <strong>{{ numero }}</strong
              >.
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <dok-footer
      :reverse-footer="true"
      :hidden-content="true"
      :downLoad-app="false"
    ></dok-footer>
  </div>
</template>

<script>
const dokFooter = () => import("../layouts/footer");
import { find, findIndex } from "lodash";
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      numero: "0708087770",
      files: [],
      success: false,
      type: "",
      percentage: 0,
      previewFiles: [],
      isStartUpload: false
    };
  },
  components: { dokFooter },
  created() {
    this.fetchFiles();
  },
  methods: {
    ...mapActions({
      uploadIdentityDocument: "file/UPLOAD_IDENTITY_DOCUMENT",
      myFiles: "file/GET_IDENTITY_DOCUMENT"
    }),
    getFiles(type) {
      return this.previewFiles.filter(file => {
        if (file.type === type) return file;
      });
    },
    fetchFiles() {
      this.myFiles({
        token: this.$route.query.token,
        onData: ({ data, ok }) => {
          if (ok) {
            this.previewFiles = data;
          }
        }
      });
    },
    deleteFile(index) {
      if (this.files[index]) this.files.splice(index, 1);
    },
    setFiles(type) {
      if (find(this.files, { type })) {
        this.files[findIndex(this.files, { type })] = {
          file: event.target.files[0],
          type
        };
      } else {
        this.files.push({ file: event.target.files[0], type });
      }
      this.$forceUpdate();
    },
    async onSent() {
      if (!this.files.length)
        return this.$vs.notify({
          time: 2000,
          text: "Veuillez choisir des fichiers",
          color: "danger",
          iconPack: "feather",
          icon: "icon-check"
        });

      this.$vs.loading();
      var okeyy = false;
      this.isStartUpload = true;
      for (let fileIndex = 0; fileIndex < this.files.length; fileIndex++) {
        const file = this.files[fileIndex];
        const fileUploadResponse = await this.uploadIdentityDocument({
          token: this.$route.query.token,
          file
        });
        this.$vs.notify({
          time: 2000,
          text: fileUploadResponse.ok
            ? "Vos documents ont été téléchargés avec succès"
            : "Echec de téléchargement des documents",
          color: fileUploadResponse.ok ? "success" : "danger",
          iconPack: "feather",
          icon: "icon-check"
        });

        okeyy = fileUploadResponse.ok;
      }

      this.$vs.loading.close();
      this.success = true;
      this.type = okeyy ? "success" : "error";
    }
  },
  mounted() {
    EventBus.$on("CountProgressUpload", percentage => {
      this.percentage = percentage;
    });
  }
};
</script>

<style></style>
